/* eslint-disable max-len */

import {actions} from './state/Reducer'
import moment from 'moment'

export const settings = {
  DarkPinkColor: '#e30072',
  PinkColor: '#fef0f7',
  applicationBaseUrl: 'https://inside.aboxofstories.com/abos_ca_api',
  authToken:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYyN2UwOWYwNDUzMjIzOGE0MmQ5ZjIyZSIsImlhdCI6MTY1MjQzOTQ0OH0.uiKrQEwNAsqnC36eTsT3_sCMGGER60R6PGTHJdJikz8',
  baseUrl: 'https://inside.aboxofstories.com/abos_ca_api',
  googleMapsApiKey: 'AIzaSyBI_cUVZYejBbwyxda_tpAEue3OZ6-MD5w',
  account: {
    saveCustomerFeedback: '/orders/saveCustomerFeedback',
    getOrders: '/orders/getOrders',
    getChoiceOrders: '/orders/getChoiceOrders',
    getCustomerBookNotChooseReasons: '/orders/getCustomerBookNotChooseReasons',
    getBuildYourOwnBooksOptions: '/buildbox/getBuildYourOwnBooksOptions',
    getBuildYourOwnPreferences: '/buildbox/getBuildYourOwnPreferences',
    getCustomerBooksOptions: '/buildbox/getCustomerBooksOptions',
    getCustomerBookStatusNames: '/orders/getCustomerBookStatusNames',
    getCustomerData: '/buildbox/getCustomerData',
    saveCustomerBookStatus: '/orders/saveCustomerBookStatus',
    saveChoiceOrderBooks: '/orders/saveChoiceOrderBooks',
    saveCustomerBookRating: '/orders/saveCustomerBookRating',
    saveCustomerBooksOptions: '/buildbox/saveCustomerBooksOptions',
    saveExcludedGenres: '/excludedgenres/saveExcludedGenres',
    GetAtsNames: '/api/v1/ats/get_ats_names',
    SaveAtsApiKey: '/api/v1/ats/connect_ats',
  },
}

export const yupValidations = {
  nameRegex: /^[A-Za-z0-9 ]*$/,
  titleRegex: /^[A-Za-z0-9 /_&-]*$/,
  phoneNumberRegex:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  cnicRegex: /^[0-9]{5}(-)[0-9]{7}(-)[0-9]{1}$/,
  dropdownRegex: /^[1-9][0-9]*$/,
}
