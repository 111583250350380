/* eslint-disable max-len */

import {
  getData,
  getDataAnonymously,
  postData,
  postDataAnonymously,
  postFormData,
  putData,
  putFormData,
} from './ApiCalls'

import {settings as s} from '../Settings'

export const getOrders = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.getOrders}`, data)
    return response
  } catch (err) {
    return null
  }
}
export const saveCustomerFeedback = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.saveCustomerFeedback}`, data)
    return response
  } catch (err) {
    return null
  }
}
export const getChoiceOrders = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.getChoiceOrders}`, data)
    return response
  } catch (err) {
    return null
  }
}
export const getCustomerBookNotChooseReasons = async () => {
  try {
    const response = await getDataAnonymously(`${s.account.getCustomerBookNotChooseReasons}`)
    return response
  } catch (err) {
    return null
  }
}

export const getCustomerData = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.getCustomerData}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const getCustomerBooksOptions = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.getCustomerBooksOptions}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveCustomerBooksOptions = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.saveCustomerBooksOptions}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveCustomerBookStatus = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.saveCustomerBookStatus}`, data)
    return response
  } catch (err) {
    return null
  }
}
export const saveChoiceOrderBooks = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.saveChoiceOrderBooks}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveCustomerBookRating = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.saveCustomerBookRating}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const getCustomerBookStatusNames = async () => {
  try {
    const response = await getDataAnonymously(`${s.account.getCustomerBookStatusNames}`)
    return response
  } catch (err) {
    return null
  }
}

export const getBuildYourOwnBooksOptions = async () => {
  try {
    const response = await getDataAnonymously(`${s.account.getBuildYourOwnBooksOptions}`)
    return response
  } catch (err) {
    return null
  }
}

export const getBuildYourOwnPreferences = async () => {
  try {
    const response = await getDataAnonymously(`${s.account.getBuildYourOwnPreferences}`)
    return response
  } catch (err) {
    return null
  }
}

export const saveExcludedGenres = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.saveExcludedGenres}`, data)
    return response
  } catch (err) {
    return null
  }
}

// extra things are below
export const register = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.register}`, data)

    return response
  } catch (err) {
    return null
  }
}

export const importJobs = async (data, user) => {
  try {
    const response = await postData(`${s.account.importJobs}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const fetchJobs = async (user) => {
  try {
    const response = await getData(`${s.account.fetchJobs}`, user)
    return response
  } catch (error) {
    return null
  }
}

export const updateJobTitle = async (data, user) => {
  try {
    const response = await postData(`${s.account.updateJobTitle}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const deleteJob = async (data, user) => {
  try {
    const response = await postData(`${s.account.deleteJob}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const deleteJobStage = async (data, user) => {
  try {
    const response = await postData(`${s.account.deleteJobStage}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const updateJobStage = async (data, user) => {
  try {
    const response = await postData(`${s.account.updateJobStage}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const sendApplicantEmailByJobId = async (data, user) => {
  try {
    const response = await postData(`${s.account.sendApplicantEmailByJobId}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const resetPasswordEmail = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.resetPasswordEmail}`, data)
    return response
  } catch (error) {
    return null
  }
}

export const passwordResetByUserId = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.passwordResetByUserId}`, data)
    return response
  } catch (error) {
    return null
  }
}

export const getPageNames = async (user) => {
  try {
    const response = await postData(`${s.account.getPageNames}`, user)
    return response
  } catch (error) {
    return null
  }
}

export const getJobStagesByJobId = async (data, user) => {
  try {
    const response = await postData(`${s.account.getJobStagesByJobId}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const getPageByName = async (data, user) => {
  try {
    const response = await postData(`${s.account.getPageByName}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const applicantFeedback = async (data, user) => {
  try {
    const response = await postData(`${s.account.applicantFeedback}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const doNotAskApplicantFeedback = async (data, user) => {
  try {
    const response = await postData(`${s.account.doNotAskApplicantFeedback}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const setApplicantFirstTimeVisit = async (data, user) => {
  try {
    const response = await postData(`${s.account.setApplicantFirstTimeVisit}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const getJobsName = async (atsId, user) => {
  try {
    const response = await getData(`${s.account.getJobsName}`.replace('$[ats_id]', atsId), user)
    return response
  } catch (error) {
    return null
  }
}

export const getPagesNames = async (user) => {
  try {
    const response = await getData(`${s.account.getPagesNames}`, user)
    return response
  } catch (error) {
    return null
  }
}
export const getPageById = async (data, user) => {
  try {
    const response = await postData(`${s.account.getPageById}`, user, data)
    return response
  } catch (error) {
    return null
  }
}
export const SavePage = async (data, user) => {
  try {
    const response = await postData(`${s.account.SavePage}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getAtsByName = async (data, user) => {
  try {
    //user = {token: s.authToken}
    //user = {token: localStorage.getItem('accessToken')}
    const response = await postData(`${s.account.getAtsByName}`, user, data)
    return response
  } catch (error) {
    return null
  }
}

export const GetAtsNames = async (user) => {
  try {
    const response = await getData(`${s.account.GetAtsNames}`, user)
    return response
  } catch (error) {
    return null
  }
}

export const activityFeed = async (user) => {
  try {
    const response = await getData(`${s.account.activityFeed}`, user)
    return response
  } catch (error) {
    return null
  }
}

export const getTemplateNamesByStageName = async (data, user) => {
  try {
    const response = await postData(`${s.account.getTemplateNamesByStageName}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const applyTemplateByStageId = async (data, user) => {
  try {
    const response = await postData(`${s.account.applyTemplateByStageId}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveJobStage = async (data, user) => {
  try {
    const response = await postData(`${s.account.SaveJobStage}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const SaveMediaFile = async (data, user) => {
  try {
    const response = await postFormData(`${s.account.SaveMediaFile}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const uploadLogo = async (data, user) => {
  try {
    const response = await postFormData(`${s.account.uploadLogo}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const applicantUploadFile = async (data, user) => {
  try {
    const response = await postFormData(`${s.account.applicantUploadFile}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const SaveAtsApiKey = async (data, user) => {
  try {
    const response = await postData(`${s.account.SaveAtsApiKey}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const welcomeScreen = async (data, user) => {
  try {
    const response = await postData(`${s.account.welcomeScreen}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const userLogin = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.login}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const emailConfimation = async (confimationCode) => {
  try {
    const response = await getDataAnonymously(
      `${s.account.emailConfimation}`.replace('$[confimationCode]', confimationCode)
    )
    return response
  } catch (err) {
    return null
  }
}

export const GetPendingUsersCount = async (user) => {
  try {
    const response = await getData(`${s.account.GetPendingUsersCount}`, user)
    return response
  } catch (error) {
    return null
  }
}

export const getResourcePersonAmountPaidReport = async (
  name,
  province,
  district,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.reports.getResourcePersonAmountPaidReport
        .replace('$[name]', name)
        .replace('$[province]', province)
        .replace('$[district]', district)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingCostAndRevenueReport = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingCostAndRevenue
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportTrainingAnalysisEvaluation = async (trainingId, user) => {
  try {
    const response = await getData(
      `${s.reports.getReportTrainingAnalysisEvaluation.replace('$[trainingId]', trainingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingProvinceWiseDisabilitySegregation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingProvinceWiseDisabilitySegregation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProvinceWiseGenderAndDisability = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getProvinceWiseGenderAndDisability
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingProvinceWiseGenderSegregation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingProvinceWiseGenderSegregation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSectorWiseParticipant = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getSectorWiseParticipant
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingSectorWiseGenderSegregation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingSectorWiseGenderSegregation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProvinceByYear = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getProvinceByYear.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingProvinceWiseSectorSegregation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingProvinceWiseSectorSegregation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAgeGroupByYear = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getAgeGroupByYear.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingSegregationByDepartment = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingSegregationByDepartment
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantsAndNoOfTrainingsByProvince = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantsAndNoOfTrainingsByProvince
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingCostsReport = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingCosts.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getMaleFemaleByDepartment = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getMaleFemaleByDepartment
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingRevenue = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingRevenue.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantSegregation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantSegregation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingAttendance = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingAttendance
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantsWhoDidNotAttend = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantsWhoDidNotAttend
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingStatus = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingStatus.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantTrendCrossBroaderTrainingArea = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantTrendCrossBroaderTrainingArea
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingConductedWithCollaboratingAgencies = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingConductedWithCollaboratingAgencies
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantTrendCrossGeographicalLocation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantTrendCrossGeographicalLocation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingPublicReportByYear = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingPublicReportByYear
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantTrendCrossSector = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantTrendCrossSector
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingLevelPublicReportByYear = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingLevelPublicReportByYear
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingTypePublicReportByYear = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingTypePublicReportByYear
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingProvinceWisePublicReportByYear = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getTrainingProvinceWisePublicReportByYear
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportTrainingEvaluation = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getReportTrainingEvaluation
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantTrendCrossDepartment = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getParticipantTrendCrossDepartment
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const get4WSOrganizationsRegisteredWithNIDMReport = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.get4WSOrganizationsRegisteredWithNIDMReport
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateProfile = async (user, data) => {
  try {
    const response = await postData(`${s.account.updateProfile}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getUsersList = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.account.getUsers
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getInActiveUsersList = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.account.getInActiveUsersList
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getAdminandUserNamesList = async (user) => {
  try {
    const response = await getData(`${s.account.getAdminandUserNamesList}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const archiveUnarchiveUsers = async (userId, archived = false, user) => {
  try {
    const response = await getData(
      `${s.account.archiveUnarchiveUsers
        .replace('$[userId]', userId)
        .replace('$[archived]', archived)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredUsers = async (
  Name,
  role,
  userStatus,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.account.getFilteredUsers
        .replace('$[Name]', Name)
        .replace('$[role]', role)
        .replace('$[userStatus]', userStatus)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const activeInactiveUsers = async (userId, isactive = false, user) => {
  try {
    const response = await getData(
      `${s.account.activeInactiveUsers
        .replace('$[userId]', userId)
        .replace('$[isactive]', isactive)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const VerifyUserEmail = async (userId) => {
  try {
    const response = await getDataAnonymously(
      `${s.account.VerifyUserEmail.replace('$[userId]', userId)}`
    )
    return response
  } catch (err) {
    return null
  }
}
export const updateUserPermissions = async (user, data) => {
  try {
    const response = await postData(`${s.account.updateUserPermissions}`, user, data)

    return response
  } catch (err) {
    return null
  }
}
export const changeUserPermissions = async (id, view, add, edit, del, user) => {
  try {
    const response = await getData(
      `${s.account.changeUserPermissions
        .replace('$[id]', id)
        .replace('$[view]', view)
        .replace('$[add]', add)
        .replace('$[edit]', edit)
        .replace('$[del]', del)}`,
      user
    )

    return response
  } catch (err) {
    return null
  }
}
export const getAllUsersList = async (user) => {
  try {
    const response = await getData(s.account.getAllUsers, user)
    return response
  } catch (err) {
    return null
  }
}
export const activeInactiveAddPermission = async (userId, isEnabled = false, user) => {
  try {
    const response = await getData(
      `${s.account.activeInactiveAddPermission
        .replace('$[userId]', userId)
        .replace('$[isEnabled]', isEnabled)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const activeInactiveEditPermission = async (userId, isEnabled = false, user) => {
  try {
    const response = await getData(
      `${s.account.activeInactiveEditPermission
        .replace('$[userId]', userId)
        .replace('$[isEnabled]', isEnabled)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const activeInactiveDeletePermission = async (userId, isEnabled = false, user) => {
  try {
    const response = await getData(
      `${s.account.activeInactiveDeletePermission
        .replace('$[userId]', userId)
        .replace('$[isEnabled]', isEnabled)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const activeInactiveViewOnlyPermission = async (userId, isEnabled = false, user) => {
  try {
    const response = await getData(
      `${s.account.activeInactiveViewOnlyPermission
        .replace('$[userId]', userId)
        .replace('$[isEnabled]', isEnabled)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const changePicture = async (data, user) => {
  try {
    const response = await postFormData(`${s.account.changePicture}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const removePicture = async (userId, user) => {
  try {
    const response = await postData(`${s.account.removePicture.replace('$[userId]', userId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getSurveys = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.tna.getSurveys
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSurveyRespondentResponse = async (surveyId, respondentId, user) => {
  try {
    const response = await getData(
      `${s.tna.getSurveyRespondentResponse
        .replace('$[surveyId]', surveyId)
        .replace('$[respondentId]', respondentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveSurvey = async (surveyId, user) => {
  try {
    const response = await getData(`${s.tna.archiveSurvey.replace('$[surveyId]', surveyId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const restoreSurvey = async (surveyId, user) => {
  try {
    const response = await getData(`${s.tna.restoreSurvey.replace('$[surveyId]', surveyId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredSurveys = async (
  name,
  surveyStatus,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.tna.getFilteredSurveys
        .replace('$[name]', name)
        .replace('$[surveyStatus]', surveyStatus)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getRespondents = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.tna.getRespondents
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredRespondents = async (
  name,
  academicQualification,
  organization,
  district,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.tna.getFilteredRespondents
        .replace('$[name]', name)
        .replace('$[academicQualification]', academicQualification)
        .replace('$[organization]', organization)
        .replace('$[district]', district)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveRespondent = async (respondentId, user) => {
  try {
    const response = await getData(
      `${s.tna.archiveRespondent.replace('$[respondentId]', respondentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreRespondent = async (respondentId, user) => {
  try {
    const response = await getData(
      `${s.tna.restoreRespondent.replace('$[respondentId]', respondentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuestions = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.tna.getQuestions
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuestionsForSurvey = async (user) => {
  try {
    const response = await getData(`${s.tna.getQuestionsForSurvey}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredQuestions = async (
  survey,
  statement,
  broaderTrainingArea,
  type,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.tna.getFilteredQuestions
        .replace('$[survey]', survey)
        .replace('$[statement]', statement)
        .replace('$[broaderTrainingArea]', broaderTrainingArea)
        .replace('$[type]', type)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const pendingUserList = async (isVerified = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.account.pendingUserList
        .replace('$[isVerified]', isVerified)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )

    return response
  } catch (err) {
    return null
  }
}
export const userApprovalByAdmin = async (userId, isVerified = false, user) => {
  try {
    const response = await getData(
      `${s.account.userApprovalByAdmin
        .replace('$[userId]', userId)
        .replace('$[isVerified]', isVerified)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const archiveQuestion = async (questionId, user) => {
  try {
    const response = await getData(
      `${s.tna.archiveQuestion.replace('$[questionId]', questionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreQuestion = async (questionId, user) => {
  try {
    const response = await getData(
      `${s.tna.restoreQuestion.replace('$[questionId]', questionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSurveyById = async (surveyId, user) => {
  try {
    const response = await getData(`${s.tna.getSurveyById.replace('$[surveyId]', surveyId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getSurveyRespondents = async (surveyId, user) => {
  try {
    const response = await getData(
      `${s.tna.getSurveyRespondents.replace('$[surveyId]', surveyId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getRespondentById = async (respondentId, user) => {
  try {
    const response = await getData(
      `${s.tna.getRespondentById.replace('$[respondentId]', respondentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuestionById = async (questionId, user) => {
  try {
    const response = await getData(
      `${s.tna.getQuestionById.replace('$[questionId]', questionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveSurvey = async (data, user) => {
  try {
    const response = await postData(`${s.tna.saveSurvey}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveRespondent = async (data, user) => {
  try {
    const response = await postData(`${s.tna.saveRespondent}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveQuestion = async (data, user) => {
  try {
    const response = await postData(`${s.tna.saveQuestion}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateSurvey = async (surveyId, data, user) => {
  try {
    const response = await putData(
      `${s.tna.updateSurvey.replace('$[surveyId]', surveyId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateRespondent = async (respondentId, data, user) => {
  try {
    const response = await putData(
      `${s.tna.updateRespondent.replace('$[respondentId]', respondentId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateQuestion = async (data, user) => {
  try {
    const response = await putData(`${s.tna.updateQuestion}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveSurveyQuestions = async (surveyId, data, user) => {
  try {
    const response = await postData(
      `${s.tna.saveSurveyQuestions.replace('$[surveyId]', surveyId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveSurveyQuestionResponse = async (surveyId, respondentId, data, user) => {
  try {
    const response = await postData(
      `${s.tna.saveSurveyQuestionResponse
        .replace('$[surveyId]', surveyId)
        .replace('$[respondentId]', respondentId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainings = async (
  organizationId = 0,
  trainingBelongsTo = 0,
  createdBy = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getTrainings
        .replace('$[organizationId]', organizationId ? organizationId : 0)
        .replace('$[trainingBelongsTo]', trainingBelongsTo)
        .replace('$[createdBy]', createdBy)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAllActivities = async (
  organizationId = 0,
  activityBelongsTo = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getActivities
        .replace('$[organizationId]', organizationId ? organizationId : 0)
        .replace('$[activityBelongsTo]', activityBelongsTo)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAllConsultantActivities = async (
  userId = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getConsultantActivities
        .replace('$[userId]', userId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveGenenalActivity = async (data, user) => {
  try {
    const response = await postFormData(`${s.training.saveActivity}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateGenenalActivity = async (data, user) => {
  try {
    const response = await postFormData(`${s.training.updateActivity}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getGeneralActivityById = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.training.getActivityById.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredGeneralActivities = async (
  activityTitle,
  stakeholderOrganization,
  delegatedTo,
  coordinatorName,
  organizationId = 0,
  activityBelongsTo = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredActivities
        .replace('$[activityTitle]', activityTitle)
        .replace('$[stakeholderOrganization]', stakeholderOrganization)
        .replace('$[delegatedTo]', delegatedTo)
        .replace('$[coordinatorName]', coordinatorName)
        .replace('$[organizationId]', organizationId)
        .replace('$[activityBelongsTo]', activityBelongsTo)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredConsultantActivities = async (
  activityTitle,
  stakeholderOrganization,
  delegatedTo,
  coordinatorName,
  userId = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredConsultantActivities
        .replace('$[activityTitle]', activityTitle)
        .replace('$[stakeholderOrganization]', stakeholderOrganization)
        .replace('$[delegatedTo]', delegatedTo)
        .replace('$[coordinatorName]', coordinatorName)
        .replace('$[userId]', userId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveGeneralActivity = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveActivity.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreGeneralActivity = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreActivity.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingModules = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingModules
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredTrainings = async (
  trainingName,
  trainingLevel,
  trainingType,
  broaderTrainingArea,
  stakeholderOrganization,
  organizationId = 0,
  trainingBelongsTo = 0,
  createdBy = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredTrainings
        .replace('$[trainingName]', trainingName)
        .replace('$[trainingLevel]', trainingLevel)
        .replace('$[trainingType]', trainingType)
        .replace('$[broaderTrainingArea]', broaderTrainingArea)
        .replace('$[stakeholderOrganization]', stakeholderOrganization)
        .replace('$[organizationId]', organizationId)
        .replace('$[trainingBelongsTo]', trainingBelongsTo)
        .replace('$[createdBy]', createdBy)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredTrainingModules = async (
  trainingName,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredTrainingModules
        .replace('$[trainingName]', trainingName)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTraining = async (trainingId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveTraining.replace('$[trainingId]', trainingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTrainingModule = async (trainingModuleId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveTrainingModule.replace('$[trainingModuleId]', trainingModuleId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreTraining = async (trainingId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreTraining.replace('$[trainingId]', trainingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingRegistrations = async (
  trainingId,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getTrainingRegistrations
        .replace('$[trainingId]', trainingId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredTrainingRegistrations = async (
  trainingId,
  name,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredTrainingRegistrations
        .replace('$[trainingId]', trainingId)
        .replace('$[name]', name)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTrainingRegistrations = async (trainingRegistrationId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveTrainingRegistration.replace(
        '$[trainingRegistrationId]',
        trainingRegistrationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreTrainingRegistrations = async (trainingRegistrationId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreTrainingRegistration.replace(
        '$[trainingRegistrationId]',
        trainingRegistrationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreTrainingModule = async (trainingModuleId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreTrainingModule.replace('$[trainingModuleId]', trainingModuleId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingSessions = async (userId = 0, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingSessions
        .replace('$[userId]', userId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredTrainingSessions = async (
  trainingName,
  trainingSessionName,
  module,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredTrainingSessions
        .replace('$[trainingName]', trainingName)
        .replace('$[trainingSessionName]', trainingSessionName)
        .replace('$[module]', module)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTrainingSession = async (trainingSessionId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveTrainingSession.replace('$[trainingSessionId]', trainingSessionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreTrainingSession = async (trainingSessionId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreTrainingSession.replace('$[trainingSessionId]', trainingSessionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getLiveTrainings = async (participantId, onlyLive = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.training.getLiveTrainings
        .replace('$[participantId]', participantId)
        .replace('$[onlyLive]', onlyLive)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantTrainings = async (participantId, user) => {
  try {
    const response = await getData(
      `${s.training.getParticipantTrainings.replace('$[participantId]', participantId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingSessionNames = async (user) => {
  try {
    const response = await getData(`${s.training.getTrainingSessionNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingModeNames = async (user) => {
  try {
    const response = await getData(`${s.training.getTrainingModeNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingCosts = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingCosts
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredTrainingCosts = async (
  trainingName,
  organization,
  project,
  approvedHead,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredTrainingCosts
        .replace('$[trainingName]', trainingName)
        .replace('$[organization]', organization)
        .replace('$[project]', project)
        .replace('$[approvedHead]', approvedHead)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTrainingCost = async (trainingCostId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveTrainingCost.replace('$[trainingCostId]', trainingCostId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreTrainingCost = async (trainingCostId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreTrainingCost.replace('$[trainingCostId]', trainingCostId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingById = async (trainingId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingById.replace('$[trainingId]', trainingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingRegistrationById = async (trainingRegistrationId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingRegistrationById.replace(
        '$[trainingRegistrationId]',
        trainingRegistrationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingModuleById = async (trainingModuleId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingModuleById.replace('$[trainingModuleId]', trainingModuleId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingSessionById = async (trainingSessionId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingSessionById.replace('$[trainingSessionId]', trainingSessionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingCostById = async (trainingCostId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingCostById.replace('$[trainingCostId]', trainingCostId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingCostsByTrainingId = async (trainingId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingCostsByTrainingId.replace('$[trainingId]', trainingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCalenderTrainings = async (year, user, broaderTrainingId = 0) => {
  try {
    const response = await getData(
      `${s.training.getCalenderTrainings
        .replace('$[year]', year)
        .replace('$[broaderTrainingId]', broaderTrainingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getMapTrainings = async (user) => {
  try {
    const response = await getData(`${s.training.getMapTrainings}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingNames = async (user, userId = 0) => {
  try {
    const response = await getData(
      `${s.training.getTrainingNames.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingModuleNames = async (user, id) => {
  try {
    const response = await getData(
      `${s.training.getTrainingModuleNames.replace('$[trainingId]', id)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProjectNames = async (user) => {
  try {
    const response = await getData(`${s.donorsAndAgencies.getProjectNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getProjectNamesByOrganizationId = async (organizationId, user) => {
  try {
    const response = await getData(
      `${s.donorsAndAgencies.getProjectNamesByOrganizationId.replace(
        '$[organizationId]',
        organizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSurveyNames = async (user) => {
  try {
    const response = await getData(`${s.tna.getSurveyNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveTraining = async (data, user) => {
  try {
    const response = await postData(`${s.training.saveTraining}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveTrainingRegistration = async (data, user) => {
  try {
    const response = await postData(`${s.training.saveTrainingRegistration}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveTrainingModule = async (data, user) => {
  try {
    const response = await postData(`${s.training.saveTrainingModule}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateTraining = async (trainingId, user, data) => {
  try {
    const response = await putData(
      `${s.training.updateTraining.replace('$[trainingId]', trainingId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateTrainingRegistration = async (trainingRegistrationId, user, data) => {
  try {
    const response = await putData(
      `${s.training.updateTrainingRegistration.replace(
        '$[trainingRegistrationId]',
        trainingRegistrationId
      )}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateTrainingModule = async (trainingModuleId, user, data) => {
  try {
    const response = await putData(
      `${s.training.updateTrainingModule.replace('$[trainingModuleId]', trainingModuleId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCityNames = async (districtId = 0, user) => {
  try {
    const response = await getData(
      `${s.settings.getCityNames.replace('$[districtId]', districtId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getBroaderTrainingAreas = async (user) => {
  try {
    const response = await getData(`${s.training.getBroaderTrainingAreas}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveTrainingSession = async (data, user) => {
  try {
    const response = await postFormData(`${s.training.saveTrainingSession}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveResourcePerson = async (data, user) => {
  try {
    const response = await postFormData(`${s.resource.saveResourcePerson}`, user, data)

    return response
  } catch (err) {
    return null
  }
}

export const saveResourcePersonTraining = async (data, user) => {
  try {
    const response = await postData(`${s.resource.saveResourcePersonTraining}`, user, data)

    return response
  } catch (err) {
    return null
  }
}

export const updateResourcePerson = async (resourcePersonId, data, user) => {
  try {
    const response = await putFormData(
      `${s.resource.updateResourcePerson.replace('$[resourcePersonId]', resourcePersonId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateResourcePersonTraining = async (
  resourcePersonId,
  trainingSessionId,
  data,
  user
) => {
  try {
    const response = await putData(
      `${s.resource.updateResourcePersonTraining
        .replace('$[resourcePersonId]', resourcePersonId)
        .replace('$[trainingSessionId]', trainingSessionId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateTrainingSession = async (trainingSessionId, user, data) => {
  try {
    const response = await putFormData(
      `${s.training.updateTrainingSession.replace('$[trainingSessionId]', trainingSessionId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveTrainingCost = async (user, data) => {
  try {
    const response = await postData(`${s.training.saveTrainingCost}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const deleteTrainingCosts = async (trainingId, organizationId, projectId, user) => {
  try {
    const response = await postData(
      `${s.training.deleteTrainingCosts
        .replace('$[trainingId]', trainingId)
        .replace('$[organizationId]', organizationId)
        .replace('$[projectId]', projectId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveTrainingAttendance = async (user, data) => {
  try {
    const response = await postFormData(`${s.training.saveTrainingAttendance}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
/* export const saveTrainingAttendance = async (user, trainingSessionId, data) => {
  try {
    const response = await postData(`${s.training.saveTrainingAttendance
      .replace('$[trainingSessionId]', trainingSessionId)}`, user, data);
    return response;
  } catch (err) {
    return null;
  }
}; */

export const getDonorsCollaboratingAgencies = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getDonorsCollaboratingAgencies
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredDonorsCollaboratingAgencies = async (
  Name,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getFilteredDonorsCollaboratingAgencies
        .replace('$[Name]', Name)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDonorsCollaboratingAgencyNames = async (user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getDonorsCollaboratingAgencyNames}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const deleteDonorsCollaboratingAgency = async (organizationId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.deleteDonorsCollaboratingAgency.replace(
        '$[organizationId]',
        organizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreDonorsCollaboratingAgency = async (organizationId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.restoreDonorsCollaboratingAgency.replace(
        '$[organizationId]',
        organizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateTrainingCost = async (trainingCostId, user, data) => {
  try {
    const response = await putData(
      `${s.training.updateTrainingCost.replace('$[trainingCostId]', trainingCostId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDonorsCollaboratingAgencyById = async (organizationId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getDonors_CollaboratingagencyById.replace(
        '$[organizationId]',
        organizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveDonorsCollaboratingAgency = async (data, user) => {
  try {
    const response = await postFormData(
      `${s.donors_collaboratingagencies.saveDonorsCollaboratingAgency}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const updateDonorsCollaboratingAgency = async (data, user) => {
  try {
    const response = await postFormData(
      `${s.donors_collaboratingagencies.saveDonorsCollaboratingAgency}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getRelationshipTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getRelationshipTypes
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getGeographicalArea = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getGeographicalArea
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDistricts = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getDistricts
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAlbumsList = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.gallery.getAlbumsList
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAlbumById = async (albumId, user) => {
  try {
    const response = await getData(`${s.gallery.getAlbumById.replace('$[albumId]', albumId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getAlbumByGalleryId = async (galleryId, itemId, user) => {
  try {
    const response = await getData(
      `${s.gallery.getAlbumByGalleryId
        .replace('$[galleryId]', galleryId)
        .replace('$[itemId]', itemId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAlbumDocument = async (albumId, user) => {
  try {
    const response = await getData(
      `${s.gallery.getAlbumDocument.replace('$[albumId]', albumId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTypeYearWiseList = async (type, year, user) => {
  try {
    const response = await getDataAnonymously(
      `${s.gallery.getTypeYearWiseList.replace('$[type]', type).replace('$[year]', year)}`
      // user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveAlbum = async (data, user) => {
  try {
    const response = await postFormData(`${s.gallery.saveAlbum}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveAlbumDocument = async (data, user) => {
  try {
    const response = await postFormData(`${s.gallery.saveAlbumDocument}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const deleteAlbum = async (albumId, user) => {
  try {
    const response = await getData(`${s.gallery.archiveAlbum.replace('$[albumId]', albumId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const restoreAlbum = async (albumId, user) => {
  try {
    const response = await getData(`${s.gallery.restoreAlbum.replace('$[albumId]', albumId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getProjects = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getProjects
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredProjects = async (Name, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getFilteredProjects
        .replace('$[Name]', Name)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const deleteProject = async (projectId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.deleteProject.replace('$[projectId]', projectId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreProject = async (projectId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.restoreProject.replace('$[projectId]', projectId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProjectById = async (projectId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getProjectById.replace('$[projectId]', projectId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getUserById = async (userId, user) => {
  try {
    const response = await getData(`${s.account.getUserById.replace('$[userId]', userId)}`, user)

    return response
  } catch (err) {
    return null
  }
}
export const saveProject = async (data, user) => {
  try {
    const response = await postFormData(`${s.donors_collaboratingagencies.saveProject}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getDocuments = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.library.getDocuments
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredDocuments = async (
  Name,
  author,
  documentCategory,
  documentCategoryOne,
  documentCategoryTwo,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.library.getFilteredDocuments
        .replace('$[name]', Name)
        .replace('$[author]', author)
        .replace('$[documentCategory]', documentCategory)
        .replace('$[documentCategoryOne]', documentCategoryOne)
        .replace('$[documentCategoryTwo]', documentCategoryTwo)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDocumentById = async (documentId, user) => {
  try {
    const response = await getData(
      `${s.library.getDocumentById.replace('$[documentId]', documentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveDocument = async (data, user) => {
  try {
    const response = await postFormData(`${s.library.saveUpdateDocument}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const deleteDocument = async (documentId, user) => {
  try {
    const response = await getData(
      `${s.library.deleteDocument.replace('$[documentId]', documentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreDocument = async (documentId, user) => {
  try {
    const response = await getData(
      `${s.library.restoreDocument.replace('$[documentId]', documentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDocCategoriesNames = async (user) => {
  try {
    const response = await getData(`${s.library.getDocCategoriesNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getDocSubCategoriesNames = async (parentCategoryId, user) => {
  try {
    const response = await getData(
      `${s.library.getDocSubCategoriesNames.replace('$[parentCategoryId]', parentCategoryId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const GetDocumentCategoryByLevel = async (level, user) => {
  try {
    const response = await getData(
      `${s.settings.GetDocumentCategoryByLevel.replace('$[level]', level)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getThematicAreaNames = async (user) => {
  try {
    const response = await getData(`${s.library.getThematicAreaNames}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentAuthorNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getDocumentAuthorNames}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentPublisherNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getDocumentPublisherNames}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const getCompilingAgencyNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getCompilingAgencyNames}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const getDonorsAndAgenciesWithProjectBriefReport = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getDonorsAndAgenciesWithProjectBriefReport
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getReconciliationFundingsAndCostReport = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getDonorsAndAgenciesReconciliationFundingsAndCostReport
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProjectFundings = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getProjectFundings
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredFundings = async (
  project,
  organization,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getFilteredFundings
        .replace('$[project]', project)
        .replace('$[organization]', organization)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const deleteProjectFundings = async (fundingId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.deleteProjectFundings.replace('$[fundingId]', fundingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreProjectFundings = async (fundingId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.restoreProjectFundings.replace('$[fundingId]', fundingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProjectFundingById = async (fundingId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getProjectFundingById.replace('$[fundingId]', fundingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFundigsbyProjectId = async (projectId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getFundigsbyProjectId.replace('$[projectId]', projectId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveProjectFunding = async (data, user) => {
  try {
    const response = await postFormData(
      `${s.donors_collaboratingagencies.saveProjectFunding}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getApprovedheadNames = async (user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.getApprovedheadNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getActivityTypeNames = async (user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.getActivityTypeNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getActivities = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getActivities
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredActivities = async (Name, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getFilteredActivities
        .replace('$[Name]', Name)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const archiveActivityCost = async (activityCostId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveActivityCost.replace('$[activityCostId]', activityCostId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const restoreActivityCost = async (activityCostId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreActivityCost.replace('$[activityCostId]', activityCostId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getActivityCostById = async (activityCostId, user) => {
  try {
    const response = await getData(
      `${s.training.getActivityCostById.replace('$[activityCostId]', activityCostId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getActivityCostsByActivityId = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.training.getActivityCostsByActivityId.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const saveActivityCost = async (user, data) => {
  try {
    const response = await postData(`${s.training.saveActivityCost}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const deleteActivityCosts = async (activityId, organizationId, projectId, user) => {
  try {
    const response = await postData(
      `${s.training.deleteActivityCosts
        .replace('$[activityId]', activityId)
        .replace('$[organizationId]', organizationId)
        .replace('$[projectId]', projectId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const updateActivityCost = async (activityCostId, user, data) => {
  try {
    const response = await putData(
      `${s.training.updateActivityCost.replace('$[activityCostId]', activityCostId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const deleteActivities = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.deleteActivities.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreActivities = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.restoreActivities.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getActivitiesCosts = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.training.getactivityCosts
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getActivitiesNames = async (user, userId = 0) => {
  try {
    const response = await getData(
      `${s.training.getactivitiesNames.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredActivityCosts = async (
  activityName,
  organization,
  project,
  approvedHead,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.training.getFilteredActivityCosts
        .replace('$[activityName]', activityName)
        .replace('$[organization]', organization)
        .replace('$[project]', project)
        .replace('$[approvedHead]', approvedHead)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getActivityById = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getActivityById.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getActivitiesById = async (activityId, user) => {
  try {
    const response = await getData(
      `${s.training.getActivitiesById.replace('$[activityId]', activityId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const saveActivity = async (data, user) => {
  try {
    const response = await postFormData(
      `${s.donors_collaboratingagencies.saveActivity}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getActivityCosts = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.getActivityCosts
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

/* export const getFilteredActivityCosts = async (Name, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.getFilteredActivityCosts
      .replace('$[Name]', Name)
      .replace('$[archived]', archived)
      .replace('$[page]', page)
      .replace('$[pageSize]', pageSize)}`, user);
    return response;
  } catch (err) {
    return null;
  }
}; */

/* export const deleteActivityCosts = async (activityCostId, user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.deleteActivityCosts.replace('$[activityCostId]', activityCostId)}`, user);
    return response;
  } catch (err) {
    return null;
  }
}; */

export const restoreActivityCosts = async (activityCostId, user) => {
  try {
    const response = await getData(
      `${s.donors_collaboratingagencies.restoreActivityCosts.replace(
        '$[activityCostId]',
        activityCostId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

/* export const getActivityCostById = async (activityCostId, user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.getActivityCostById.replace('$[activityCostId]', activityCostId)}`, user);
    return response;
  } catch (err) {
    return null;
  }
}; */

/* export const saveActivityCost = async (data, user) => {
  try {
    const response = await postFormData(`${s.donors_collaboratingagencies.saveActivityCost}`, user, data);
    return response;
  } catch (err) {
    return null;
  }
}; */

export const getActivityNames = async (user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.getActivityNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getFundingNames = async (user) => {
  try {
    const response = await getData(`${s.donors_collaboratingagencies.getFundingNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getApprovedHeads = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getApprovedHeads.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getApprovedHeadById = async (approvedheadId, user) => {
  try {
    const response = await getData(
      `${s.settings.getApprovedHeadById.replace('$[approvedheadId]', approvedheadId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveApprovedHead = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveApprovedHead}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getBroaderTrainingAreasList = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getBroaderTrainingAreas
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getBroaderTrainingAreaById = async (broaderTrainingAreaId, user) => {
  try {
    const response = await getData(
      `${s.settings.getBroaderTrainingAreaById.replace(
        '$[broaderTrainingAreaId]',
        broaderTrainingAreaId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveBroaderTrainingArea = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveBroaderTrainingArea}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateBroaderTrainingArea = async (data, user) => {
  try {
    const response = await postData(`${s.settings.updateBroaderTrainingArea}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getSurveySections = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getSurveySections.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSurveySectionById = async (surveySectionId, user) => {
  try {
    const response = await getData(
      `${s.settings.getSurveySectionById.replace('$[surveySectionId]', surveySectionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveSurveySection = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveSurveySection}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateSurveySection = async (data, user) => {
  try {
    const response = await postData(`${s.settings.updateSurveySection}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getSurveySectionNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getSurveySectionNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getReportSurveyList = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getReportSurveyList.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportMainRespondents = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getReportMainRespondents
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportSurveyAnalysis = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.reports.getReportSurveyAnalysis
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getActivityTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getActivityTypes.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getActivityTypeById = async (activityTypeId, user) => {
  try {
    const response = await getData(
      `${s.settings.getActivityTypeById.replace('$[activityTypeId]', activityTypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveActivityType = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveActivityType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updatePassword = async (user, data) => {
  try {
    const response = await postDataAnonymously(`${s.account.changePassword}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const createPassword = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.createPassword}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const registerUser = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.account.register}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const getParticipants = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.participant.getParticipants
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredParticipants = async (
  trainingName,
  participantName,
  academicQualification,
  organization,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.participant.getFilteredParticipants
        .replace('$[trainingName]', trainingName)
        .replace('$[participantName]', participantName)
        .replace('$[academicQualification]', academicQualification)
        .replace('$[organization]', organization)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveParticipant = async (participantId, user) => {
  try {
    const response = await getData(
      `${s.participant.archiveParticipant.replace('$[participantId]', participantId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreParticipant = async (participantId, user) => {
  try {
    const response = await getData(
      `${s.participant.restoreParticipant.replace('$[participantId]', participantId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreGroup = async (groupId, user) => {
  try {
    const response = await getData(
      `${s.participant.restoreGroup.replace('$[groupId]', groupId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveGroup = async (groupId, user) => {
  try {
    const response = await getData(
      `${s.participant.archiveGroup.replace('$[groupId]', groupId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantNames = async (user) => {
  try {
    const response = await getData(`${s.participant.getParticipantNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantById = async (participantId, user) => {
  try {
    const response = await getData(
      `${s.participant.getParticipantById.replace('$[participantId]', participantId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveParticipant = async (data, user) => {
  try {
    const response = await postData(`${s.participant.saveParticipant}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateParticipant = async (data, user) => {
  try {
    const response = await postData(`${s.participant.updateParticipant}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getOrganizationTypeNames = async () => {
  try {
    const response = await getDataAnonymously(s.settings.getOrganizationTypeNames)
    return response
  } catch (err) {
    return null
  }
}

export const getOrganizationNames = async (organizationTypeId, user) => {
  try {
    const response = await getDataAnonymously(
      `${s.settings.getOrganizationNames.replace('$[organizationTypeId]', organizationTypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAcademicQualificationNames = async () => {
  try {
    const response = await getDataAnonymously(s.settings.getAcademicQualificationNames)
    return response
  } catch (err) {
    return null
  }
}

export const getGroups = async (archived, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.participant.getGroups
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredGroups = async (reportType, name, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.participant.getFilteredGroups
        .replace('$[reportType]', reportType)
        .replace('$[name]', name)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveGroup = async (data, user) => {
  try {
    const response = await postData(`${s.participant.saveGroup}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateGroup = async (data, user) => {
  try {
    const response = await postData(`${s.participant.updateGroup}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getGroupById = async (groupId, user) => {
  try {
    const response = await getData(
      `${s.participant.getGroupById.replace('$[groupId]', groupId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourses = async (
  userId = 0,
  isActive = true,
  approved = false,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getCourses
        .replace('$[userId]', userId)
        .replace('$[isActive]', isActive)
        .replace('$[approved]', approved)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredCourses = async (
  Name,
  userId = 0,
  isActive = true,
  approved = false,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getFilteredCourses
        .replace('$[Name]', Name)
        .replace('$[userId]', userId)
        .replace('$[isActive]', isActive)
        .replace('$[approved]', approved)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveCourse = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.archiveCourse.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreCourse = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.restoreCourse.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const approveCourse = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.approveCourse.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSelfPacedCoursesStatusReport = async (user) => {
  try {
    const response = await getData(`${s.courses.getSelfPacedCoursesStatusReport}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getSelfPacedCourseswithParticipantsReport = async (user) => {
  try {
    const response = await getData(`${s.courses.getSelfPacedCourseswithParticipantsReport}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getSelfPacedOfferedCoursesReport = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.courses.getSelfPacedOfferedCoursesReport
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const InActiveCourse = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.InActiveCourse.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAttachments = async (
  userId = 0,
  topicId,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getAttachments
        .replace('$[userId]', userId)
        .replace('$[topicId]', topicId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveAttachment = async (attachmentId, user) => {
  try {
    const response = await getData(
      `${s.courses.archiveAttachment.replace('$[attachmentId]', attachmentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreAttachment = async (attachmentId, user) => {
  try {
    const response = await getData(
      `${s.courses.restoreAttachment.replace('$[attachmentId]', attachmentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuizzes = async (userId = 0, topicId, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.courses.getQuizzes
        .replace('$[userId]', userId)
        .replace('$[topicId]', topicId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveQuiz = async (quizId, user) => {
  try {
    const response = await getData(`${s.courses.archiveQuiz.replace('$[quizId]', quizId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const restoreQuiz = async (quizId, user) => {
  try {
    const response = await getData(`${s.courses.restoreQuiz.replace('$[quizId]', quizId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getQuizQuestions = async (
  userId = 0,
  quizId,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getQuizQuestions
        .replace('$[userId]', userId)
        .replace('$[quizId]', quizId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveQuizQuestion = async (quizQuestionId, user) => {
  try {
    const response = await getData(
      `${s.courses.archiveQuizQuestion.replace('$[quizQuestionId]', quizQuestionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreQuizQuestion = async (quizQuestionId, user) => {
  try {
    const response = await getData(
      `${s.courses.restoreQuizQuestion.replace('$[quizQuestionId]', quizQuestionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuizQuestionById = async (quizQuestionId, user) => {
  try {
    const response = await getData(
      `${s.courses.getQuizQuestionById.replace('$[quizQuestionId]', quizQuestionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuizNames = async (topicId, user) => {
  try {
    const response = await getData(`${s.courses.getQuizNames.replace('$[topicId]', topicId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getQuizQuestionTexts = async (user) => {
  try {
    const response = await getData(`${s.courses.getQuizQuestionTexts}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getTopicNames = async (sectionId, user) => {
  try {
    const response = await getData(
      `${s.courses.getTopicNames.replace('$[sectionId]', sectionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAttachmentById = async (attachmentId, user) => {
  try {
    const response = await getData(
      `${s.courses.getAttachmentById.replace('$[attachmentId]', attachmentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getQuizById = async (quizId, user) => {
  try {
    const response = await getData(`${s.courses.getQuizById.replace('$[quizId]', quizId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveCourse = async (data, user) => {
  try {
    const response = await postData(`${s.courses.saveCourse}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveAttachment = async (data, user) => {
  try {
    const response = await postFormData(`${s.courses.saveAttachment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveQuiz = async (data, user) => {
  try {
    const response = await postFormData(`${s.courses.saveQuiz}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveQuizQuestion = async (data, user) => {
  try {
    const response = await postData(`${s.courses.saveQuizQuestion}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateCourse = async (data, user) => {
  try {
    const response = await postData(`${s.courses.updateCourse}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const IsCourseCodeExist = async (courseCode, user) => {
  try {
    const response = await getData(
      `${s.courses.IsCourseCodeExist.replace('$[courseCode]', courseCode)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const offerCourse = async (data, user, isCourseOffering = true) => {
  try {
    const response = await postData(
      `${s.courses.offerCourse.replace('$[isCourseOffering]', isCourseOffering)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseById = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.getCourseById.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDetailedCourseById = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.getDetailedCourseById.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getRegistrations = async (
  userId = 0,
  courseId,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getRegistrations
        .replace('$[userId]', userId)
        .replace('$[courseId]', courseId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveRegistration = async (registrationId, user) => {
  try {
    const response = await getData(
      `${s.courses.archiveRegistration.replace('$[registrationId]', registrationId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreRegistration = async (registrationId, user) => {
  try {
    const response = await getData(
      `${s.courses.restoreRegistration.replace('$[registrationId]', registrationId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveRegistration = async (data, user) => {
  try {
    const response = await postData(`${s.courses.saveRegistration}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateRegistration = async (data, user) => {
  try {
    const response = await postData(`${s.courses.updateRegistration}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getRegistrationById = async (registrationId, user) => {
  try {
    const response = await getData(
      `${s.courses.getRegistrationById.replace('$[registrationId]', registrationId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseNames = async (userId = 0, user) => {
  try {
    const response = await getData(`${s.courses.getCourseNames.replace('$[userId]', userId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getMyCourseNames = async (participantId, user) => {
  try {
    const response = await getData(
      `${s.courses.getMyCourseNames.replace('$[participantId]', participantId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSections = async (
  userId = 0,
  courseId = 0,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getSections
        .replace('$[userId]', userId)
        .replace('$[courseId]', courseId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveSection = async (sectionId, user) => {
  try {
    const response = await getData(
      `${s.courses.archiveSection.replace('$[sectionId]', sectionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreSection = async (sectionId, user) => {
  try {
    const response = await getData(
      `${s.courses.restoreSection.replace('$[sectionId]', sectionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSectionNames = async (courseId, user) => {
  try {
    const response = await getData(
      `${s.courses.getSectionNames.replace('$[courseId]', courseId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveSection = async (data, user) => {
  try {
    const response = await postData(`${s.courses.saveSection}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateSection = async (data, user) => {
  try {
    const response = await postData(`${s.courses.updateSection}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getSectionById = async (sectionId, user) => {
  try {
    const response = await getData(
      `${s.courses.getSectionById.replace('$[sectionId]', sectionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTopics = async (userId, sectionId, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.courses.getTopics
        .replace('$[userId]', userId)
        .replace('$[sectionId]', sectionId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTopic = async (topicId, user) => {
  try {
    const response = await getData(`${s.courses.archiveTopic.replace('$[topicId]', topicId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const restoreTopic = async (topicId, user) => {
  try {
    const response = await getData(`${s.courses.restoreTopic.replace('$[topicId]', topicId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveTopic = async (data, user) => {
  try {
    const response = await postData(`${s.courses.saveTopic}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateTopic = async (data, user) => {
  try {
    const response = await postData(`${s.courses.updateTopic}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getTopicById = async (topicId, user) => {
  try {
    const response = await getData(`${s.courses.getTopicById.replace('$[topicId]', topicId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getQuizAttempts = async (
  userId = 0,
  participantId,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.courses.getQuizAttempts
        .replace('$[userId]', userId)
        .replace('$[participantId]', participantId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveQuizAttempt = async (quizAttemptId, user) => {
  try {
    const response = await getData(
      `${s.courses.archiveQuizAttempt.replace('$[quizAttemptId]', quizAttemptId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreQuizAttempt = async (quizAttemptId, user) => {
  try {
    const response = await getData(
      `${s.courses.restoreQuizAttempt.replace('$[quizAttemptId]', quizAttemptId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveQuizAttempt = async (data, user) => {
  try {
    const response = await postData(`${s.courses.saveQuizAttempt}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateQuizAttempt = async (data, user) => {
  try {
    const response = await postData(`${s.courses.updateQuizAttempt}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getQuizAttemptById = async (quizAttemptId, user) => {
  try {
    const response = await getData(
      `${s.courses.getQuizAttemptById.replace('$[quizAttemptId]', quizAttemptId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProvinceNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getProvinceNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getDistrictNames = async (provinceId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDistrictNames.replace('$[provinceId]', provinceId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDistrictNamesWithOutProvinceId = async (user) => {
  try {
    const response = await getData(`${s.settings.getDistrictNamesWithoutProvinceId}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getEmailSmsLogs = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.participant.getEmailSmsLogs.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredEmailSmsLogs = async (reportType, group, subject, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.participant.getFilteredEmailSmsLogs
        .replace('$[reportType]', reportType)
        .replace('$[group]', group)
        .replace('$[subject]', subject)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmailSmsLogById = async (logId, user) => {
  try {
    const response = await getData(
      `${s.participant.getEmailSmsLogById.replace('$[logId]', logId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSmsGroups = async (user) => {
  try {
    const response = await getData(`${s.participant.getSmsGroups}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getEmailGroups = async (user) => {
  try {
    const response = await getData(`${s.participant.getEmailGroups}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const sendSmsToParticipants = async (data, user) => {
  try {
    const response = await postData(`${s.participant.sendSmsToParticipants}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const sendEmailToParticipants = async (data, user) => {
  try {
    const response = await postData(`${s.participant.sendEmailToParticipants}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingAttendanceById = async (trainingId, userId = 0, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingAttendanceById
        .replace('$[trainingId]', trainingId)
        .replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getIndividual = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.consultant.getIndividual
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredIndividual = async (
  name,
  academicQualification,
  organization,
  province,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.consultant.getFilteredIndividual
        .replace('$[name]', name)
        .replace('$[academicQualification]', academicQualification)
        .replace('$[organization]', organization)
        .replace('$[province]', province)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const deleteIndividual = async (resourcePersonId, user) => {
  try {
    const response = await getData(
      `${s.consultant.deleteIndividual.replace('$[resourcePersonId]', resourcePersonId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const restoreIndividual = async (resourcePersonId, user) => {
  try {
    const response = await getData(
      `${s.consultant.restoreIndividual.replace('$[resourcePersonId]', resourcePersonId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getIndividualById = async (resourcePersonId, user) => {
  try {
    const response = await getData(
      `${s.consultant.getIndividualById.replace('$[resourcePersonId]', resourcePersonId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const saveIndividual = async (data, user) => {
  try {
    const response = await postFormData(`${s.consultant.saveIndividual}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const updateIndividual = async (resourcePersonId, data, user) => {
  try {
    const response = await putFormData(
      `${s.consultant.updateIndividual.replace('$[resourcePersonId]', resourcePersonId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}
export const getIndividualNames = async (user) => {
  try {
    const response = await getData(`${s.consultant.getIndividualNames}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const ActivateIndividualLogin = async (resourcePersonId, user) => {
  try {
    const response = await getData(
      `${s.consultant.ActivateIndividualLogin.replace('$[id]', resourcePersonId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getConsultantOrganizations = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.consultant.getConsultantOrganizations
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveConsultantOrganization = async (data, user) => {
  try {
    const response = await postFormData(`${s.consultant.saveConsultantOrganization}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateConsultantOrganization = async (data, user) => {
  try {
    const response = await postFormData(s.consultant.updateConsultantOrganization, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredConsultantOrganizations = async (
  organizationName,
  organizationType,
  relationshipType,
  headName,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.consultant.getFilteredConsultantOrganizations
        .replace('$[organizationName]', organizationName)
        .replace('$[organizationType]', organizationType)
        .replace('$[relationshipType]', relationshipType)
        .replace('$[headName]', headName)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getConsultantOrganizationById = async (fourWOrganizationId, user) => {
  try {
    const response = await getData(
      `${s.consultant.getConsultantOrganizationById.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getConsultantOrganizationByUserId = async (userId, user) => {
  try {
    const response = await getData(
      `${s.consultant.getConsultantOrganizationByUserId.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveConsultantOrganization = async (fourWOrganizationId, user) => {
  try {
    const response = await getData(
      `${s.consultant.archiveConsultantOrganization.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreConsultantOrganization = async (fourWOrganizationId, user) => {
  try {
    const response = await getData(
      `${s.consultant.restoreConsultantOrganization.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getConsultantOrganizationNames = async (user) => {
  try {
    const response = await getData(`${s.consultant.getConsultantOrganizationNames}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const getConsultantNames = async (type, user) => {
  try {
    const response = await getData(
      `${s.consultant.getConsultantNames.replace('$[type]', type)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const createConsultantOrganizationLogin = async (fourWOrganizationId, user) => {
  try {
    const response = await postData(
      `${s.consultant.createConsultantOrganizationLogin.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getResourcePersons = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.resource.getResourcePersons
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getResourcePersonsReport = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.resource.getResourcePersonsReport
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const ActivateResourcePersonLogin = async (resourcePersonId, password) => {
  try {
    const response = await getDataAnonymously(
      `${s.resource.ActivateResourcePersonLogin.replace('$[id]', resourcePersonId).replace(
        '$[password]',
        password
      )}`
    )
    return response
  } catch (err) {
    return null
  }
}

export const sendPasswordGenerationEmail = async (url, email) => {
  try {
    const response = await getDataAnonymously(
      `${s.resource.sendPasswordGenerationEmail.replace('$[url]', url).replace('$[email]', email)}`
    )
    return response
  } catch (err) {
    return null
  }
}

export const sendPasswordGenerationEmailEmployee = async (url, email) => {
  try {
    const response = await getDataAnonymously(
      `${s.employee.sendPasswordGenerationEmail.replace('$[url]', url).replace('$[email]', email)}`
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredResourcePersons = async (
  name,
  academicQualification,
  organization,
  province,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.resource.getFilteredResourcePersons
        .replace('$[name]', name)
        .replace('$[academicQualification]', academicQualification)
        .replace('$[organization]', organization)
        .replace('$[province]', province)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const deleteResourcePerson = async (resourcePersonId, user) => {
  try {
    const response = await getData(
      `${s.resource.deleteResourcePerson.replace('$[resourcePersonId]', resourcePersonId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreResourcePerson = async (resourcePersonId, user) => {
  try {
    const response = await getData(
      `${s.resource.restoreResourcePerson.replace('$[resourcePersonId]', resourcePersonId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getResourcePersonsTrainings = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.resource.getResourcePersonsTrainings
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredResourcePersonsTrainings = async (
  name,
  training,
  session,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.resource.getFilteredResourcePersonsTrainings
        .replace('$[name]', name)
        .replace('$[training]', training)
        .replace('$[session]', session)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const deleteResourcePersonsTraining = async (trainingSessionId, user) => {
  try {
    const response = await getData(
      `${s.resource.deleteResourcePersonsTraining.replace(
        '$[trainingSessionId]',
        trainingSessionId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreResourcePersonsTraining = async (trainingSessionId, user) => {
  try {
    const response = await getData(
      `${s.resource.restoreResourcePersonsTraining.replace(
        '$[trainingSessionId]',
        trainingSessionId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getResourcePersonsNames = async (user) => {
  try {
    const response = await getData(`${s.resource.getResourcePersonsNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getResourcePersonById = async (resourcePersonId, user) => {
  try {
    const response = await getDataAnonymously(
      `${s.resource.getResourcePersonById.replace('$[resourcePersonId]', resourcePersonId)}`
    )
    return response
  } catch (err) {
    return null
  }
}

export const getResourcePersonTrainingById = async (resourcePersonId, trainingSessionId, user) => {
  try {
    const response = await getData(
      `${s.resource.getResourcePersonTrainingById
        .replace('$[resourcePersonId]', resourcePersonId)
        .replace('$[trainingSessionId]', trainingSessionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getThematicAreas = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getThematicAreas.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDisabilityTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getDisabilityTypes.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const GetFundingAgency = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.GetFundingAgency.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseDeveloper = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getCourseDeveloper.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const GetNamePrefix = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.GetNamePrefix.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const GetProjectActivity = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.GetProjectActivity.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseOwner = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getCourseOwner.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getRegistrationAuthority = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getRegistrationAuthority
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDisabilityTypeNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getDisabilityTypeNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveThematicArea = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveThematicArea}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const SaveFundingAgency = async (data, user) => {
  try {
    const response = await postData(`${s.settings.SaveFundingAgency}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveDisabilityType = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveDisabilityType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveCourseDeveloper = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveCourseDeveloper}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const SaveNamePrefix = async (data, user) => {
  try {
    const response = await postData(`${s.settings.SaveNamePrefix}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const SaveProjectActivity = async (data, user) => {
  try {
    const response = await postData(`${s.settings.SaveProjectActivity}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveCourseOwner = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveCourseOwner}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const saveRegistrationAuthority = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveRegistrationAuthority}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateThematicArea = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateThematicArea}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const UpdateFundingAgency = async (data, user) => {
  try {
    const response = await putData(`${s.settings.UpdateFundingAgency}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateDisabilityType = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateDisabilityType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateCourseOwner = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateCourseOwner}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const updateRegistrationAuthority = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateRegistrationAuthority}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateCourseDeveloper = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateCourseDeveloper}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const UpdateNamePrefix = async (data, user) => {
  try {
    const response = await putData(`${s.settings.UpdateNamePrefix}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const UpdateProjectActivity = async (data, user) => {
  try {
    const response = await putData(`${s.settings.UpdateProjectActivity}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getThematicAreaById = async (thematicareaId, user) => {
  try {
    const response = await getData(
      `${s.settings.getThematicAreaById.replace('$[thematicareaId]', thematicareaId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const CheckDuplicateEnum = async (title, enumName, user) => {
  try {
    const response = await getData(
      `${s.settings.CheckDuplicateEnum.replace('$[title]', title).replace(
        '$[enumName]',
        enumName
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDisabilityTypeById = async (disabilityTypeId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDisabilityTypeById.replace('$[disabilityTypeId]', disabilityTypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const GetFundingAgencyById = async (disabilityTypeId, user) => {
  try {
    const response = await getData(
      `${s.settings.GetFundingAgencyById.replace('$[disabilityTypeId]', disabilityTypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseOwnerById = async (id, user) => {
  try {
    const response = await getData(`${s.settings.getCourseOwnerById.replace('$[id]', id)}`, user)
    return response
  } catch (err) {
    return null
  }
}
export const getRegistrationAuthorityById = async (id, user) => {
  try {
    const response = await getData(
      `${s.settings.getRegistrationAuthorityById.replace('$[id]', id)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseDeveloperById = async (id, user) => {
  try {
    const response = await getData(
      `${s.settings.getCourseDeveloperById.replace('$[id]', id)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const GetNamePrefixById = async (id, user) => {
  try {
    const response = await getData(`${s.settings.GetNamePrefixById.replace('$[id]', id)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const GetProjectActivityById = async (id, user) => {
  try {
    const response = await getData(
      `${s.settings.GetProjectActivityById.replace('$[id]', id)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProvinces = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getProvinces.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentAuthors = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getDocumentAuthors.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentPublishers = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getDocumentPublishers
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getCompilingAgencies = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getCompilingAgencies
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getOrganizationTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getOrganizationTypes
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveProvince = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveProvince}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const saveDocumentAuthor = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveDocumentAuthor}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const saveDocumentPublisher = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveDocumentPublisher}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const SaveCompilingAgency = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.SaveCompilingAgency}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveOrganizationType = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveOrganizationType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateProvince = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateProvince}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const updateDocumentAuthor = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateDocumentAuthor}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const updateDocumentPublisher = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateDocumentPublisher}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const updateCompilingAgency = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateCompilingAgency}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateOrganizationType = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateOrganizationType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getProvinceById = async (provinceId, user) => {
  try {
    const response = await getData(
      `${s.settings.getProvinceById.replace('$[provinceId]', provinceId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentAuthorById = async (authorId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDocumentAuthorById.replace('$[authorId]', authorId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentPublisherById = async (publisherId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDocumentPublisherById.replace('$[publisherId]', publisherId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getCompilingAgencyById = async (agencyId, user) => {
  try {
    const response = await getData(
      `${s.settings.getCompilingAgencyById.replace('$[agencyId]', agencyId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getOrganizationTypeById = async (organizationTypeId, user) => {
  try {
    const response = await getData(
      `${s.settings.getOrganizationTypeById.replace('$[organizationTypeId]', organizationTypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSettingsDistricts = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getDistricts.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAcademicQualifications = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getAcademicQualifications
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveDistrict = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveDistrict}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveAcademicQualification = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveAcademicQualification}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateDistrict = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateDistrict}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateAcademicQualification = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateAcademicQualification}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getDistrictById = async (districtId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDistrictById.replace('$[districtId]', districtId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAcademicQualificationById = async (academicQualificationId, user) => {
  try {
    const response = await getData(
      `${s.settings.getAcademicQualificationById.replace(
        '$[academicQualificationId]',
        academicQualificationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCities = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getCities.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAreasOfSpecialities = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getAreasofSpecialities
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAreasofSpecialityNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getAreasofSpecialityNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveCity = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveCity}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveAreasOfSpeciality = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveAreasofSpeciality}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateCity = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateCity}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateAreasOfSpeciality = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateAreasofSpeciality}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getCityById = async (cityId, user) => {
  try {
    const response = await getData(`${s.settings.getCityById.replace('$[cityId]', cityId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getDocumentCategories = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getDocumentCategories
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDocumentCategoryNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getDocumentCategoryNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveDocumentCategory = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveDocumentCategory}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateDocumentCategory = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateDocumentCategory}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getDocumentCategoryById = async (documentCategoryId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDocumentCategoryById.replace('$[documentcategoryId]', documentCategoryId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getWebsiteContents = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getWebsiteContents.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveWebsiteContent = async (data, user) => {
  try {
    const response = await postFormData(`${s.settings.saveWebsiteContent}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateWebsiteContent = async (data, user) => {
  try {
    const response = await putFormData(`${s.settings.updateWebsiteContent}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getWebsiteContentById = async (contentId, user) => {
  try {
    const response = await getData(
      `${s.settings.getWebsiteContentById.replace('$[contentId]', contentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAreasOfSpecialityById = async (areasOfSpecialityId, user) => {
  try {
    const response = await getData(
      `${s.settings.getAreasofSpecialityById.replace(
        '$[areasOfSpecialityId]',
        areasOfSpecialityId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportDashboardAdmin = async (user) => {
  try {
    const response = await getData(`${s.dashboard.getReportDashboardAdmin}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getReportDashboardResourcePerson = async (userId, user) => {
  try {
    const response = await getData(
      `${s.dashboard.getReportDashboardResourcePerson.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportDashboardEmployee = async (userId, user) => {
  try {
    const response = await getData(
      `${s.dashboard.getReportDashboardEmployee.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportDashboardStakeholder = async (userId, user) => {
  try {
    const response = await getData(
      `${s.dashboard.getReportDashboardStakeholder.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportDashboardConsultant = async (userId, user) => {
  try {
    const response = await getData(
      `${s.dashboard.getReportDashboardConsultant.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getNIDMTrainingStats = async () => {
  try {
    const response = await getDataAnonymously(s.public.getNIDMTrainingStats)
    return response
  } catch (err) {
    return null
  }
}

export const getReportTrainingYearWiseParticipationSummary = async () => {
  try {
    const response = await getDataAnonymously(
      s.public.getReportTrainingYearWiseParticipationSummary
    )
    return response
  } catch (err) {
    return null
  }
}

export const getReportOverallSectorRepresentation = async () => {
  try {
    const response = await getDataAnonymously(s.public.getReportOverallSectorRepresentation)
    return response
  } catch (err) {
    return null
  }
}

export const getStakeholderTrainingStats = async () => {
  try {
    const response = await getDataAnonymously(s.public.getStakeholderTrainingStats)
    return response
  } catch (err) {
    return null
  }
}

export const getNIDMTrainingsMap = async () => {
  try {
    const response = await getDataAnonymously(s.public.getNIDMTrainingsMap)
    return response
  } catch (err) {
    return null
  }
}

export const getStakeholderTrainingsMap = async () => {
  try {
    const response = await getDataAnonymously(s.public.getStakeholderTrainingsMap)
    return response
  } catch (err) {
    return null
  }
}

export const getArchivedTrainingsByYear = async (year) => {
  try {
    const response = await getDataAnonymously(s.public.getTrainingsByYear.replace('$[year]', year))
    return response
  } catch (err) {
    return null
  }
}

export const getUpcomingTrainings = async () => {
  try {
    const response = await getDataAnonymously(s.public.getUpcomingTrainings)
    return response
  } catch (err) {
    return null
  }
}

export const getAlbums = async (albumType, itemId, userId = 0) => {
  try {
    const response = await getDataAnonymously(
      s.public.getAlbums
        .replace('$[albumType]', albumType)
        .replace('$[itemId]', itemId)
        .replace('$[userId]', userId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const publicGetTypeYearWiseList = async (type, year, user) => {
  try {
    const response = await getDataAnonymously(
      `${s.public.getTypeYearWiseList.replace('$[type]', type).replace('$[year]', year)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAlbum = async (albumId) => {
  try {
    const response = await getDataAnonymously(s.public.getAlbum.replace('$[albumId]', albumId))
    return response
  } catch (err) {
    return null
  }
}

export const getAvailableCourses = async (participantId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getAvailableCourses.replace('$[participantId]', participantId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCourseDetailsById = async (courseId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getCourseDetailsById.replace('$[courseId]', courseId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const getMainSliderPictures = async () => {
  try {
    const response = await getDataAnonymously(s.public.getMainSliderPictures)
    return response
  } catch (err) {
    return null
  }
}

export const getNews = async () => {
  try {
    const response = await getDataAnonymously(s.public.getNews)
    return response
  } catch (err) {
    return null
  }
}

export const getEvents = async () => {
  try {
    const response = await getDataAnonymously(s.public.getEvents)
    return response
  } catch (err) {
    return null
  }
}

export const getStakeHolderActivities = async () => {
  try {
    const response = await getDataAnonymously(s.public.getStakeHolderActivities)
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredPublicDocuments = async (
  categoryOne,
  categoryTwo,
  categoryThree,
  query,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getDataAnonymously(
      `${s.public.getFilteredPublicDocuments
        .replace('$[categoryOne]', categoryOne)
        .replace('$[categoryTwo]', categoryTwo)
        .replace('$[categoryThree]', categoryThree)
        .replace('$[query]', query)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getProminentStakeHolders = async () => {
  try {
    const response = await getDataAnonymously(s.public.getProminentStakeHolders)
    return response
  } catch (err) {
    return null
  }
}

export const getGuestBook = async () => {
  try {
    const response = await getDataAnonymously(s.public.getGuestBook)
    return response
  } catch (err) {
    return null
  }
}

export const getPictureGallery = async () => {
  try {
    const response = await getDataAnonymously(s.public.getPictureGallery)
    return response
  } catch (err) {
    return null
  }
}

export const getOurPartners = async () => {
  try {
    const response = await getDataAnonymously(s.public.getOurPartners)
    return response
  } catch (err) {
    return null
  }
}

export const getDocumentCategoriesWithoutAuth = async () => {
  try {
    const response = await getDataAnonymously(s.public.getDocumentCategories)
    return response
  } catch (err) {
    return null
  }
}
export const getDocumentCategoriesByLevelWithoutAuth = async (level) => {
  try {
    const response = await getDataAnonymously(
      s.public.getDocumentCategoriesByLevel.replace('$[level]', level)
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDocumentsByCategoryId = async (documentCategoryId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getDocumentsByCategoryId.replace('$[documentCategoryId]', documentCategoryId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const get4WOrganizations = async (archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.stakeholderTraining.get4WOrganizations
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const save4WOrganization = async (data, user) => {
  try {
    const response = await postFormData(`${s.stakeholderTraining.save4WOrganization}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const update4WOrganization = async (data, user) => {
  try {
    const response = await postFormData(s.stakeholderTraining.update4WOrganization, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getFiltered4WOrganizations = async (
  organizationName,
  organizationType,
  relationshipType,
  headName,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.stakeholderTraining.getFiltered4WOrganizations
        .replace('$[organizationName]', organizationName)
        .replace('$[organizationType]', organizationType)
        .replace('$[relationshipType]', relationshipType)
        .replace('$[headName]', headName)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const get4WOrganizationById = async (fourWOrganizationId, user) => {
  try {
    const response = await getData(
      `${s.stakeholderTraining.get4WOrganizationById.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const get4WOrganizationByUserId = async (userId, user) => {
  try {
    const response = await getData(
      `${s.stakeholderTraining.get4WOrganizationByUserId.replace('$[userId]', userId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archive4WOrganization = async (fourWOrganizationId, user) => {
  try {
    const response = await getData(
      `${s.stakeholderTraining.archive4WOrganization.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restore4WOrganization = async (fourWOrganizationId, user) => {
  try {
    const response = await getData(
      `${s.stakeholderTraining.restore4WOrganization.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const get4WOrganizationNames = async (user) => {
  try {
    const response = await getData(`${s.stakeholderTraining.get4WOrganizationNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const createStakeholderLogin = async (fourWOrganizationId, user) => {
  try {
    const response = await postData(
      `${s.stakeholderTraining.createStakeholderLogin.replace(
        '$[fourWOrganizationId]',
        fourWOrganizationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const enrollCourse = async (data, user) => {
  try {
    const response = await postData(`${s.public.enrollCourse}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveParticipantTrainingRegistration = async (data, user) => {
  try {
    const response = await postData(`${s.public.saveTrainingRegistration}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getRoles = async (user) => {
  try {
    const response = await getData(`${s.userManagement.getRoles}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getRoleById = async (id, user) => {
  try {
    const response = await getData(`${s.userManagement.getRoleById.replace('$[roleId]', id)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveRole = async (data, user) => {
  try {
    const response = await postData(`${s.userManagement.saveRole}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateRole = async (id, data, user) => {
  try {
    const response = await postData(
      `${s.userManagement.updateRole.replace('$[roleId]', id)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const activeInActiveRole = async (id, status, user) => {
  try {
    const response = await getData(
      `${s.userManagement.activeInActiveRole
        .replace('$[roleId]', id)
        .replace('$[status]', status)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getModules = async (user) => {
  try {
    const response = await getData(`${s.userManagement.getModules}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getModuleById = async (id, user) => {
  try {
    const response = await getData(
      `${s.userManagement.getModuleById.replace('$[moduleId]', id)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveModule = async (data, user) => {
  try {
    const response = await postData(`${s.userManagement.saveModule}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateModule = async (id, data, user) => {
  try {
    const response = await postData(
      `${s.userManagement.updateModule.replace('$[moduleId]', id)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const activeInActiveModule = async (id, status, user) => {
  try {
    const response = await getData(
      `${s.userManagement.activeInActiveModule
        .replace('$[moduleId]', id)
        .replace('$[status]', status)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getSubModules = async (user) => {
  try {
    const response = await getData(`${s.userManagement.getSubModules}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getSubModuleById = async (id, user) => {
  try {
    const response = await getData(
      `${s.userManagement.getSubModuleById.replace('$[subModuleId]', id)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveSubModule = async (data, user) => {
  try {
    const response = await postData(`${s.userManagement.saveSubModule}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateSubModule = async (id, data, user) => {
  try {
    const response = await postData(
      `${s.userManagement.updateSubModule.replace('$[subModuleId]', id)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

export const activeInActiveSubModule = async (id, status, user) => {
  try {
    const response = await getData(
      `${s.userManagement.activeInActiveSubModule
        .replace('$[subModuleId]', id)
        .replace('$[status]', status)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveRolePermissions = async (data, user) => {
  try {
    const response = await postData(`${s.userManagement.saveRolePermissions}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getRolePermissions = async (roleId, userid, user) => {
  try {
    const response = await getData(
      `${s.userManagement.getRolePermissions
        .replace('$[roleId]', roleId)
        .replace('$[userid]', userid)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingDetails = async (trainingId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getTrainingDetails.replace('$[trainingId]', trainingId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const getClientTotalPendingOrdersCount = async (selectedClient, user) => {
  try {
    const response = await getData(
      `${s.order.getClientTotalPendingOrders.replace('$[id]', selectedClient)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getClients = async (user) => {
  try {
    const response = await getData(s.client.getAllClients, user)
    return response
  } catch (err) {
    return null
  }
}

export const getUsers = async (user) => {
  try {
    const response = await getData(s.user.getAllUsers.replace('$[clientId]', user.clientId), user)
    return response
  } catch (err) {
    return null
  }
}

export const getEcommerceSystems = async (user) => {
  try {
    const response = await getData(`${s.ecommerceSystem.getAllEcommerceSystems}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getMarketplaces = async (user) => {
  try {
    const response = await getData(`${s.marketplace.getAllMarketplaces}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getShippingTemplates = async (selectedClient, user) => {
  try {
    const response = await getData(
      `${s.shippingTemplate.getAllShippingTemplates.replace('$[clientId]', selectedClient)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getShippingTemplate = async (shippingTemplateId, user) => {
  try {
    const response = await getData(
      `${s.shippingTemplate.getShippingTemplate.replace('$[id]', shippingTemplateId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getCountries = async (user) => {
  try {
    const response = await getData(`${s.allMarketplacesIntegrations.getCountries}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getClientStatistics = async (selectedClient, user) => {
  try {
    const response = await getData(s.stats.getStats.replace('$[clientId]', selectedClient), user)
    return response
  } catch (err) {
    return null
  }
}

export const getClientMarketPlaceSettingsList = async (clientId, user) => {
  try {
    const result = await getData(
      `${s.client.getClientMarketPlaceSettingsList.replace('$[id]', clientId)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getClientMarketplaces = async (clientId, user) => {
  try {
    const result = await getData(
      `${s.client.getClientMarketplaces.replace('$[id]', clientId)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getClientMarketplaceReport = async (
  clientId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.client.getClientMarketplaceReport
        .replace('$[clientId]', clientId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const moveProductsToDraft = async (productIds, user) => {
  try {
    const result = await postData(`${s.product.moveProductsToDraft}`, user, productIds)
    return result
  } catch (err) {
    return null
  }
}

export const getPainPoints = async (clientId, startDate, endDate, user) => {
  try {
    const result = await getData(
      `${s.reports.getPainPoints
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getSuperStar = async (clientId, startDate, endDate, user) => {
  try {
    const result = await getData(
      `${s.reports.getSuperStar
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getRisingStar = async (clientId, startDate, endDate, user) => {
  try {
    const result = await getData(
      `${s.reports.getRisingStar
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getMonthlyRunRate = async (
  clientId,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getMonthlyRunRate
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getAnnualRunRate = async (
  clientId,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getAnnualRunRate
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductByOrders = async (
  clientId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getProductByOrders
        .replace('$[clientId]', clientId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductByRevenue = async (
  clientId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getProductByRevenue
        .replace('$[clientId]', clientId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductVariantDetails = async (
  clientId,
  productId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getProductVariantDetails
        .replace('$[clientId]', clientId)
        .replace('$[productId]', productId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getMonthlyRunRateNegation = async (
  clientId,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getMonthlyRunRateNegation
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getAnnualRunRateNegation = async (
  clientId,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getAnnualRunRateNegation
        .replace('$[clientId]', clientId)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductByOrdersNegation = async (
  clientId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getProductByOrdersNegation
        .replace('$[clientId]', clientId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductByRevenueNegation = async (
  clientId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getProductByRevenueNegation
        .replace('$[clientId]', clientId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductVariantDetailsNegation = async (
  clientId,
  productId,
  marketplaceIds,
  startDate,
  endDate,
  orderTags,
  customerTags,
  customerTypes,
  user
) => {
  try {
    const result = await getData(
      `${s.reports.getProductVariantDetailsNegation
        .replace('$[clientId]', clientId)
        .replace('$[productId]', productId)
        .replace('$[marketplaceIds]', marketplaceIds)
        .replace('$[startDate]', startDate)
        .replace('$[endDate]', endDate)
        .replace('$[orderTags]', orderTags)
        .replace('$[customerTags]', customerTags)
        .replace('$[customerTypes]', customerTypes)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getFeedSubmissionResult = async (getLatest, selectedClient, user) => {
  try {
    const result = await getData(
      `${s.amazonIntegration.getFeedSubmissionResult.replace(
        '$[clientId]',
        selectedClient
      )}/${getLatest}`,
      user,
      selectedClient
    )
    return result
  } catch (err) {
    return null
  }
}

export const getEcommerceSystem = async (ecommerceId, user) => {
  try {
    const result = await getData(
      `${s.ecommerceSystem.getEcommerceSystem.replace('$[id]', ecommerceId)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getMarketplace = async (marketplaceId, user) => {
  try {
    const result = await getData(
      `${s.marketplace.getMarketplace.replace('$[id]', marketplaceId)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getTagTypes = async (user) => {
  try {
    const response = await getData(`${s.tag.getTagTypes}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getCustomTags = async (clientId, user) => {
  try {
    const response = await getData(`${s.tag.getAllCustomTags.replace('$[id]', clientId)}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getCustomTag = async (tagId, user) => {
  try {
    const result = await getData(`${s.tag.getCustomTag.replace('$[id]', tagId)}`, user)
    return result
  } catch (err) {
    return null
  }
}

export const getSystemTags = async (user) => {
  try {
    const response = await getData(`${s.tag.getAllSystemTags}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getSystemTag = async (tagId, user) => {
  try {
    const result = await getData(`${s.tag.getSystemTag.replace('$[id]', tagId)}`, user)
    return result
  } catch (err) {
    return null
  }
}

export const getClient = async (selectedClient, user) => {
  try {
    const result = await getData(s.client.getClient.replace('$[id]', selectedClient), user)
    return result
  } catch (err) {
    return null
  }
}

export const getClientMarketplaceConfigurations = async (selectedClient, user) => {
  try {
    const result = await getData(
      `${s.marketplace.getClientMarketplaceConfigurations.replace('$[clientId]', selectedClient)}`,
      user
    )
    return result
  } catch (err) {
    return null
  }
}

export const getProductStatuses = async (user) => {
  try {
    const result = await getData(s.product.getProductStatuses, user)
    return result
  } catch (err) {
    return null
  }
}

export const getAllSurveys = async () => {
  try {
    const response = await getDataAnonymously(s.public.getAllSurveys)
    return response
  } catch (err) {
    return null
  }
}

export const getSurveyByIdWithQuestions = async (surveyId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getSurveyByIdWithQuestions.replace('$[surveyId]', surveyId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAllDisabilityTypeNames = async () => {
  try {
    const response = await getDataAnonymously(s.public.getDisabilityTypeNames)
    return response
  } catch (err) {
    return null
  }
}

export const getAllOrganizationTypeNames = async () => {
  try {
    const response = await getDataAnonymously(s.public.getOrganizationTypeNames)
    return response
  } catch (err) {
    return null
  }
}

export const getAllOrganizationNamesByOrganizationTypeId = async (organizationTypeId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getOrganizationNamesByOrganizationTypeId.replace(
        '$[organizationTypeId]',
        organizationTypeId
      )
    )
    return response
  } catch (err) {
    return null
  }
}

export const getAllAcademicQualificationNames = async () => {
  try {
    const response = await getDataAnonymously(s.public.getAcademicQualificationNames)
    return response
  } catch (err) {
    return null
  }
}

export const getAllProvinceNames = async () => {
  try {
    const response = await getDataAnonymously(s.public.getProvinceNames)
    return response
  } catch (err) {
    return null
  }
}

export const getAllDistrictNamesByProvinceId = async (provinceId) => {
  try {
    const response = await getDataAnonymously(
      s.public.getDistrictNamesByProvinceId.replace('$[provinceId]', provinceId)
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveRespondentAnonymously = async (data) => {
  try {
    const response = await postDataAnonymously(`${s.public.saveRespondent}`, data)
    return response
  } catch (err) {
    return null
  }
}

export const saveSurveyResponse = async (data) => {
  try {
    const response = await postDataAnonymously(s.public.saveSurveyResponse, data)
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingEvaluations = async (
  trainingId = 0,
  participantId = 0,
  archived = false,
  page,
  pageSize,
  user,
  year
) => {
  try {
    const response = await getData(
      `${s.training.getTrainingEvaluations
        .replace('$[trainingId]', trainingId)
        .replace('$[year]', year)
        .replace('$[participantId]', participantId)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getParticipantTrainingEvaluations = async (participantId = 0, user) => {
  try {
    const response = await getData(
      `${s.training.getParticipantTrainingEvaluations.replace('$[participantId]', participantId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const archiveTrainingEvaluation = async (trainingEvaluationId, user) => {
  try {
    const response = await getData(
      `${s.training.archiveTrainingEvaluation.replace(
        '$[trainingEvaluationId]',
        trainingEvaluationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreTrainingEvaluation = async (trainingEvaluationId, user) => {
  try {
    const response = await getData(
      `${s.training.restoreTrainingEvaluation.replace(
        '$[trainingEvaluationId]',
        trainingEvaluationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const activeInActiveTrainingEvaluation = async (trainingEvaluationId, user) => {
  try {
    const response = await postData(
      `${s.training.activeInActiveTrainingEvaluation.replace(
        '$[trainingEvaluationId]',
        trainingEvaluationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const cancelTrainingEvaluation = async (trainingEvaluationId, user) => {
  try {
    const response = await postData(
      `${s.training.cancelTrainingEvaluation.replace(
        '$[trainingEvaluationId]',
        trainingEvaluationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveTrainingEvaluationResponse = async (data, user) => {
  try {
    const response = await postData(s.training.saveTrainingEvaluationResponse, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const getTrainingEvaluationById = async (trainingEvaluationId, user) => {
  try {
    const response = await getData(
      `${s.training.getTrainingEvaluationById.replace(
        '$[trainingEvaluationId]',
        trainingEvaluationId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const get4WTrainingActivityNames = async (user) => {
  try {
    const response = await getData(`${s.stakeholderTraining.get4WTrainingActivityNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const getFilteredAlbums = async (name, archived = false, page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.gallery.getFilteredAlbums
        .replace('$[name]', name)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//..employee module
export const getEmployees = async (archived = false, page, pageSize, user, userId = 0) => {
  try {
    const response = await getData(
      `${s.employee.getEmployees
        .replace('$[archived]', archived)
        .replace('$[empId]', userId)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredEmployees = async (
  Name,
  wing,
  directorate,
  cell,
  archived = false,
  page,
  pageSize,
  user
) => {
  try {
    const response = await getData(
      `${s.employee.getFilteredEmployees
        .replace('$[employeeName]', Name)
        .replace('$[wing]', wing)
        .replace('$[directorate]', directorate)
        .replace('$[cell]', cell)

        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmployeesNames = async (user) => {
  try {
    const response = await getData(`${s.employee.getEmployeesNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const archiveEmployee = async (employeeId, user) => {
  try {
    const response = await getData(
      `${s.employee.archiveEmployee.replace('$[employeeId]', employeeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreEmployee = async (employeeId, user) => {
  try {
    const response = await getData(
      `${s.employee.restoreEmployee.replace('$[employeeId]', employeeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmployeeById = async (employeeId, user) => {
  try {
    const response = await getDataAnonymously(
      `${s.employee.getEmployeeById.replace('$[employeeId]', employeeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveEmployee = async (data, user) => {
  try {
    const response = await postFormData(`${s.employee.saveEmployee}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateEmployee = async (employeeId, data, user) => {
  try {
    const response = await postFormData(
      `${s.employee.updateEmployee.replace('$[employeeId]', employeeId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}
export const activateEmployeeLogin = async (employeeId, password) => {
  try {
    const response = await getDataAnonymously(
      `${s.employee.ActivateEmployeeLoginFeature.replace('$[id]', employeeId).replace(
        '$[password]',
        password
      )}`
    )
    return response
  } catch (err) {
    return null
  }
}

//..events below

export const getEmployeeEvents = async (archived = false, page, pageSize, userId = 0, user) => {
  try {
    const response = await getData(
      `${s.employee.getEmployeeEvents
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[userId]', userId)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
export const getFilteredEmployeeEvents = async (
  title,
  province,
  archived = false,
  page,
  pageSize,
  user,
  userId
) => {
  try {
    const response = await getData(
      `${s.employee.getFilteredEmployeeEvents
        .replace('$[title]', title)
        .replace('$[province]', province)
        .replace('$[archived]', archived)
        .replace('$[page]', page)
        .replace('$[userId]', userId)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEventsNames = async (user) => {
  try {
    const response = await getData(`${s.employee.getEventsNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const archiveEvent = async (eventId, user) => {
  try {
    const response = await getData(
      `${s.employee.archiveEvent.replace('$[eventId]', eventId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const restoreEvent = async (eventId, user) => {
  try {
    const response = await getData(
      `${s.employee.restoreEvent.replace('$[eventId]', eventId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEventById = async (eventId, user) => {
  try {
    const response = await getData(
      `${s.employee.getEventById.replace('$[eventId]', eventId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const saveEmployeeEvent = async (data, user) => {
  try {
    const response = await postFormData(`${s.employee.saveEmployeeEvent}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateEmployeeEvent = async (eventId, data, user) => {
  try {
    const response = await postFormData(
      `${s.employee.updateEmployeeEvent.replace('$[eventId]', eventId)}`,
      user,
      data
    )
    return response
  } catch (err) {
    return null
  }
}

//...directorate types..//

export const getDirectorateTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getDirectorateTypes.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getDirectorateTypeNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getDirectorateTypeNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveDirectorateType = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveDirectorateType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateDirectorateType = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateDirectorateType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getDirectorateTypeById = async (directorateTypeId, user) => {
  try {
    const response = await getData(
      `${s.settings.getDirectorateTypeById.replace('$[directorateTypeId]', directorateTypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...ndma sections types..//

export const getNDMASections = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getNDMASections.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getNDMASectionNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getNDMASectionNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveNDMASection = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveNDMASection}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateNDMASection = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateNDMASection}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getNDMASectionById = async (sectionId, user) => {
  try {
    const response = await getData(
      `${s.settings.getNDMASectionById.replace('$[sectionId]', sectionId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...ndma Appointment types..//

export const getNDMAAppointments = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getNDMAAppointments.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getNDMAAppointmentNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getNDMAAppointmentNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveNDMAAppointment = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveNDMAAppointment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateNDMAAppointment = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateNDMAAppointment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getNDMAAppointmentById = async (appointmentId, user) => {
  try {
    const response = await getData(
      `${s.settings.getNDMAAppointmentById.replace('$[appointmentId]', appointmentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Federal Departments..//

export const getFederalDepartments = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getFederalDepartments
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getFederalDepartmentNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getFederalDepartmentNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveFederalDepartment = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveFederalDepartment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateFederalDepartment = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateFederalDepartment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getFederalDepartmentById = async (departmentId, user) => {
  try {
    const response = await getData(
      `${s.settings.getFederalDepartmentById.replace('$[departmentId]', departmentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Provincial Departments..//

export const getProvincialDepartments = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getProvincialDepartments
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getProvincialDepartmentNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getProvincialDepartmentNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveProvincialDepartment = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveProvincialDepartment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateProvincialDepartment = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateProvincialDepartment}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getProvincialDepartmentById = async (departmentId, user) => {
  try {
    const response = await getData(
      `${s.settings.getProvincialDepartmentById.replace('$[departmentId]', departmentId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Employee Event Types..//

export const getEmployeeEventTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventTypes
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmployeeEventTypeNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getEmployeeEventTypeNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveEmployeeEventType = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveEmployeeEventType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateEmployeeEventType = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateEmployeeEventType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getEmployeeEventTypeById = async (eventtypeId, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventTypeById.replace('$[eventtypeId]', eventtypeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Employee Event Conduct Mode..//

export const getEmployeeEventConductModes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventConductModes
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmployeeEventConductModeNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getEmployeeEventConductModeNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveEmployeeEventConductModes = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveEmployeeEventConductModes}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateEmployeeEventConductMode = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateEmployeeEventConductMode}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getEmployeeEventConductModeById = async (conductmodeId, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventConductModeById.replace('$[conductmodeId]', conductmodeId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Employee Event Funding Status..//

export const getEmployeeEventFundingStatuses = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventFundingStatuses
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmployeeEventFundingStatusNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getEmployeeEventFundingStatusNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveEmployeeEventFundingStatus = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveEmployeeEventFundingStatus}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateEmployeeEventFundingStatus = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateEmployeeEventFundingStatus}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getEmployeeEventFundingStatusById = async (fundingstatusId, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventFundingStatusById.replace(
        '$[fundingstatusId]',
        fundingstatusId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Employee Event Organizer..//

export const getEmployeeEventOrganizers = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventOrganizers
        .replace('$[page]', page)
        .replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getEmployeeEventOrganizersNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getEmployeeEventOrganizersNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveEmployeeEventOrganizers = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveEmployeeEventOrganizers}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateEmployeeEventOrganizer = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateEmployeeEventOrganizer}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getEmployeeEventOrganizerById = async (eventorganizeriId, user) => {
  try {
    const response = await getData(
      `${s.settings.getEmployeeEventOrganizerById.replace(
        '$[eventorganizeriId]',
        eventorganizeriId
      )}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...NDMA Wings..//

export const getNDMAWings = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getNDMAWings.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getNDMAWingsNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getNDMAWingsNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveNDMAWings = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveNDMAWings}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateNDMAWing = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateNDMAWing}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getNDMAWingById = async (wingId, user) => {
  try {
    const response = await getData(
      `${s.settings.getNDMAWingById.replace('$[wingId]', wingId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

//...Employee Armed Force Types..//

export const getArmedForceTypes = async (page, pageSize, user) => {
  try {
    const response = await getData(
      `${s.settings.getArmedForceTypes.replace('$[page]', page).replace('$[pageSize]', pageSize)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}

export const getArmedForceTypesNames = async (user) => {
  try {
    const response = await getData(`${s.settings.getArmedForceTypesNames}`, user)
    return response
  } catch (err) {
    return null
  }
}

export const saveArmedForceTypes = async (data, user) => {
  try {
    const response = await postData(`${s.settings.saveArmedForceTypes}`, user, data)
    return response
  } catch (err) {
    return null
  }
}

export const updateArmedForceType = async (data, user) => {
  try {
    const response = await putData(`${s.settings.updateArmedForceType}`, user, data)
    return response
  } catch (err) {
    return null
  }
}
export const getArmedForceTypeById = async (forceId, user) => {
  try {
    const response = await getData(
      `${s.settings.getArmedForceTypeById.replace('$[forceId]', forceId)}`,
      user
    )
    return response
  } catch (err) {
    return null
  }
}
